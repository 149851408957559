import { 
  DashboardOutlined, 
  UserOutlined,
  DotChartOutlined,
  FundOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.menu',
  icon: DashboardOutlined,
  breadcrumb: false,
  rules: ['admin'],
  submenu: [
    {
      key: 'menu-confection',
      path: `${APP_PREFIX_PATH}/menu/confection`,
      title: 'sidenav.menu.confection',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'menu-products',
      path: `${APP_PREFIX_PATH}/menu/products`,
      title: 'sidenav.menu.products',
      icon: DotChartOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'menu-steps',
      path: `${APP_PREFIX_PATH}/menu/steps`,
      title: 'sidenav.menu.steps',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'menu-profile',
      path: `${APP_PREFIX_PATH}/pages/user-list`,
      title: 'Usuários',
      icon: UserOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'menu-supplies',
      path: `${APP_PREFIX_PATH}/menu/supplies`,
      title: 'Insumos',
      icon: ProfileOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
]

export default navigationConfig;